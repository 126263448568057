import React, { Suspense } from "react"

import { Routes, Route, Navigate } from "react-router-dom"

import { AllProviders } from "@components/AllProviders"
import Layout from "@views/Layout"
import AuthedSkeletonLayout from "@views/Layout/AuthedSkeletonLayout"
import SkeletonLayout from "@views/auth/Layout/SkeletonLayout"
import Lazy from "@views/lazy"

import routes from "@constants/routes"

import "./App.css"

declare global {
  interface Window {
    HubSpotConversations: any // eslint-disable-line @typescript-eslint/no-explicit-any
    hbspt: {
      forms: {
        create: (options: {
          formId: string
          portalId: string
          region: string
          target: string
        }) => void
      }
    }
    analytics: {
      page: (options: { referrer: string; url: string }) => void
      identify: (userId: string, options: Record<string, unknown>) => void
    }
  }
}

const App: React.FC = () => {
  return (
    <AllProviders>
      <Routes>
        {/* routes not needing authentication */}
        <Route
          path={`${routes.SIGN_IN}/*`}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.SignIn />
            </Suspense>
          }
        />
        <Route
          path={routes.SIGN_UP}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.SignUp />
            </Suspense>
          }
        />
        <Route
          path={routes.ACCESS_DENIED}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.NotAuthorized />
            </Suspense>
          }
        />
        <Route
          path={routes.NO_ORGANIZATION}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.NoOrganization />
            </Suspense>
          }
        />
        <Route
          path={routes.PROFILE_BUILDER}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.ProfileBuilder />
            </Suspense>
          }
        />

        {/* authenticated routes below, please alphabetize! */}

        {/* Does not render on PRODUCTION */}
        <Route
          path="/"
          element={
            <Suspense fallback={<AuthedSkeletonLayout />}>
              <Lazy.Home />
            </Suspense>
          }
        />

        <Route element={<Layout />}>
          <Route
            path={routes.COMPANIES}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Companies />
              </Suspense>
            }
          />
          <Route
            path={routes.CONTACTS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Contacts />
              </Suspense>
            }
          />
          <Route
            path={routes.CUSTOMERS}
            element={<Navigate replace to={routes.COMPANIES} />}
          />
          <Route
            path={routes.DASHBOARD}
            element={
              // TODO: Remove this Navigate once the dashboard is ready
              <Navigate replace to={routes.ORDERS} />

              // Re-enable below when the Navigate above is removed
              // <Suspense fallback={<AuthedSkeletonLayout />}>
              //   <Lazy.Dashboard />
              // </Suspense>
            }
          />
          <Route
            path={routes.COMPANIES}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Companies />
              </Suspense>
            }
          />
          <Route
            path={routes.CUSTOMERS}
            element={<Navigate replace to={routes.COMPANIES} />}
          />
          <Route
            path={routes.ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Orders />
              </Suspense>
            }
          />
          <Route
            path={routes.QUOTES}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Quotes />
              </Suspense>
            }
          />
          <Route
            path={routes.PURCHASE_ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.PurchaseOrders />
              </Suspense>
            }
          />
          <Route
            path={routes.VENDORS}
            element={<Navigate replace to={routes.COMPANIES} />}
          />
          <Route
            path={routes.PRODUCTION}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Production />
              </Suspense>
            }
          />
          <Route
            path={routes.RECEIVING}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Receiving />
              </Suspense>
            }
          />
          <Route
            path={routes.INVENTORY}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Inventory />
              </Suspense>
            }
          />
          <Route
            path={`${routes.INVENTORY}/:inventoryId`}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Inventory />
              </Suspense>
            }
          />
          <Route
            path={routes.ITEMS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Items />
              </Suspense>
            }
          />
          <Route
            path={`${routes.ITEMS}${routes.ITEM_TAB}`}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Items />
              </Suspense>
            }
          />
          <Route
            path={routes.MATERIALS}
            element={<Navigate replace to={routes.ITEMS} />}
          />
          <Route
            path={`${routes.MATERIALS}${routes.ITEM_TAB}`}
            element={
              <Navigate replace to={`${routes.ITEMS}${routes.ITEM_TAB}`} />
            }
          />
          <Route
            path={routes.MANUFACTURING_ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.ManufacturingOrders />
              </Suspense>
            }
          />
          <Route
            path={routes.MO_DETAIL}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.ManufacturingOrderDetail />
              </Suspense>
            }
          />
          <Route
            path={routes.ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Orders />
              </Suspense>
            }
          />
          <Route
            path={routes.ORDER_ITEMS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.OrderItems />
              </Suspense>
            }
          />
          <Route
            path={routes.PLANNING}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Planning />
              </Suspense>
            }
          />
          <Route
            path={routes.PRODUCTION}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Production />
              </Suspense>
            }
          />
          <Route
            path={routes.PRODUCTS}
            element={<Navigate replace to={routes.ITEMS} />}
          />
          <Route
            path={`${routes.PRODUCTS}${routes.ITEM_TAB}`}
            element={
              <Navigate replace to={`${routes.ITEMS}${routes.ITEM_TAB}`} />
            }
          />
          <Route
            path={routes.PURCHASE_ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.PurchaseOrders />
              </Suspense>
            }
          />
          <Route
            path={routes.RECEIVING}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Receiving />
              </Suspense>
            }
          />
          <Route
            path={routes.REPORTS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Reports />
              </Suspense>
            }
          />

          <Route
            path={routes.SPECIFIC_REPORT}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Reports />
              </Suspense>
            }
          />

          <Route
            path={routes.SHIPPING}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Shipping />
              </Suspense>
            }
          />
          <Route
            path={`${routes.SHIPPING}${routes.SHIPPING_GROUP_TAB}`}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Shipping />
              </Suspense>
            }
          />
          <Route
            path={`${routes.SHIPPING}${routes.SHIPPING_TAB}`}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Shipping />
              </Suspense>
            }
          />
          <Route
            path={routes.USERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.Users />
              </Suspense>
            }
          />
          <Route
            path={`${routes.USERS}/:userId`}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.UserDetail />
              </Suspense>
            }
          />
          <Route
            path={routes.VENDORS}
            element={<Navigate replace to={routes.COMPANIES} />}
          />
          <Route
            path={routes.WORK_ORDERS}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.WorkOrders />
              </Suspense>
            }
          />
          <Route
            path={routes.WO_DETAIL}
            element={
              <Suspense fallback={<AuthedSkeletonLayout />}>
                <Lazy.WorkOrderDetail />
              </Suspense>
            }
          />
        </Route>

        {/* leave as last item - fallback */}
        <Route
          path={routes.NOT_FOUND}
          element={
            <Suspense fallback={<SkeletonLayout />}>
              <Lazy.NotFound />
            </Suspense>
          }
        />
      </Routes>
    </AllProviders>
  )
}

export default App

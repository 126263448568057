import React from "react"

import AdjustmentsHorizontalIcon from "@heroicons/react/24/outline/AdjustmentsHorizontalIcon"
import ArchiveBoxIcon from "@heroicons/react/24/outline/ArchiveBoxIcon"
import ArrowPathRoundedSquareIcon from "@heroicons/react/24/outline/ArrowPathRoundedSquareIcon"
import AtSymbolIcon from "@heroicons/react/24/outline/AtSymbolIcon"
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon"
import CalculatorIcon from "@heroicons/react/24/outline/CalculatorIcon"
import ChartBarSquareIcon from "@heroicons/react/24/outline/ChartBarSquareIcon"
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon"
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon"
import ClipboardIcon from "@heroicons/react/24/outline/ClipboardIcon"
import CogIcon from "@heroicons/react/24/outline/CogIcon"
import CubeIcon from "@heroicons/react/24/outline/CubeIcon"
import DocumentCurrencyDollarIcon from "@heroicons/react/24/outline/DocumentCurrencyDollarIcon"
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon"
import HomeIcon from "@heroicons/react/24/outline/HomeIcon"
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon"
import PencilIcon from "@heroicons/react/24/outline/PencilIcon"
import QrCodeIcon from "@heroicons/react/24/outline/QrCodeIcon"
import ShieldCheckIcon from "@heroicons/react/24/outline/ShieldCheckIcon"
import ShoppingBagIcon from "@heroicons/react/24/outline/ShoppingBagIcon"
import ShoppingCartIcon from "@heroicons/react/24/outline/ShoppingCartIcon"
import Square3Stack3DIcon from "@heroicons/react/24/outline/Square3Stack3DIcon"
import TagIcon from "@heroicons/react/24/outline/TagIcon"
import TruckIcon from "@heroicons/react/24/outline/TruckIcon"
import UsersIcon from "@heroicons/react/24/outline/UsersIcon"
import WrenchIcon from "@heroicons/react/24/outline/WrenchIcon"

import { SubMenu } from "@views/Layout/SidebarMenu/SubMenu"
import { SettingsPageName } from "@views/organizationSettings/SettingsModal/types"

import routes, {
  inventorySubRoutes,
  itemSubRoutes,
  jobSubRoutes,
  manufacturingOrderSubRoutes,
  moveInventorySubRoutes,
  receivingSubRoutes,
  shippingModalSubRoutes,
  shippingSubRoutes,
  warehouseLocationSubRoutes,
  workOrderSubRoutes,
} from "@constants/routes"
import { itemTypes } from "@constants/serverEnums"

export const hubs = {
  HOME: "home",
  FINANCE: "finance",
  OPERATIONS: "operations",
  PEOPLE: "people",
  SALES: "sales",
  SETTINGS: "settings",
  REPORTING: "reporting",
}

export const features = {
  COMPANIES: "companies",
  CONTACTS: "contacts",
  CUSTOMERS: "customers", // deprecated
  ITEMS: "items",
  INVENTORY: "inventory",
  MATERIALS: "materials", // deprecated
  MANUFACTURING_ORDERS: "manufacturing_orders",
  ORDERS: "orders",
  ORDER_ITEMS: "order_items",
  QUOTES: "quotes",
  PLANNING: "planning",
  POS: "purchase_orders",
  PRODUCTION: "production",
  PRODUCTS: "products", // deprecated
  RECEIVING: "receiving",
  REPORTS: "reports",
  SHIPPING: "shipping",
  USERS: "users",
  WORK_ORDERS: "work_orders",
  VENDORS: "vendors", // deprecated
}

export interface FeatureNavItem {
  icon: React.ReactElement | null
  route: string
  translatedTextKey: string
  show?: string
}

export interface FeatureNavs {
  [key: string]: FeatureNavItem
}

// all top level routes
export const featureNavs: FeatureNavs = {
  companies: {
    icon: <BuildingOfficeIcon />,
    route: routes.COMPANIES,
    translatedTextKey: "features_companies",
  },
  contacts: {
    icon: <AtSymbolIcon />,
    route: routes.CONTACTS,
    translatedTextKey: "features_contacts",
  },
  dashboard: {
    icon: <HomeIcon />,
    route: routes.DASHBOARD,
    translatedTextKey: "features_scorecard",
  },
  inventory: {
    icon: <CubeIcon />,
    route: routes.INVENTORY,
    translatedTextKey: "features_inventory",
  },
  items: {
    icon: <TagIcon />,
    route: routes.ITEMS,
    translatedTextKey: "features_items",
  },
  manufacturing_orders: {
    icon: <ClipboardDocumentListIcon />,
    route: routes.MANUFACTURING_ORDERS,
    translatedTextKey: "features_manufacturingOrders",
  },
  orders: {
    icon: <ShoppingBagIcon />,
    route: routes.ORDERS,
    translatedTextKey: "features_orders",
  },
  order_items: {
    icon: <ListBulletIcon />, // TODO: change icon
    route: routes.ORDER_ITEMS,
    translatedTextKey: "features_orderItems",
  },
  quotes: {
    icon: <DocumentCurrencyDollarIcon />,
    route: routes.QUOTES,
    translatedTextKey: "features_quotes",
  },
  planning: {
    icon: <CalculatorIcon />,
    route: routes.PLANNING,
    translatedTextKey: "features_planning",
  },
  production: {
    icon: <ClipboardIcon />,
    route: routes.PRODUCTION,
    translatedTextKey: "features_production",
  },
  purchase_orders: {
    icon: <ShoppingCartIcon />,
    route: routes.PURCHASE_ORDERS,
    translatedTextKey: "features_purchaseOrders",
  },
  receiving: {
    icon: <TruckIcon />,
    route: routes.RECEIVING,
    translatedTextKey: "features_receiving",
  },
  reports: {
    icon: <ChartBarSquareIcon />,
    route: routes.REPORTS,
    translatedTextKey: "features_reports",
  },
  shipping: {
    icon: <TruckIcon />,
    route: routes.SHIPPING,
    translatedTextKey: "features_shipping",
  },
  users: {
    icon: <UsersIcon />,
    route: routes.USERS,
    translatedTextKey: "features_users",
  },
  work_orders: {
    icon: <ClipboardIcon />,
    route: routes.WORK_ORDERS,
    translatedTextKey: "features_workOrders",
  },
}

export const itemSubNavs: FeatureNavs = {
  itemDetails: {
    icon: null,
    route: itemSubRoutes.ITEM_DETAILS,
    translatedTextKey: "features_itemSubNavs_itemDetails",
  },
  itemVariants: {
    icon: null,
    route: itemSubRoutes.ITEM_VARIANTS,
    translatedTextKey: "features_itemSubNavs_itemVariants",
    show: "hasVariants",
  },
  itemVendors: {
    icon: null,
    route: itemSubRoutes.ITEM_VENDORS,
    translatedTextKey: "features_itemSubNavs_itemVendors",
    show: itemTypes.BUY,
  },
  itemPurchasing: {
    icon: null,
    route: itemSubRoutes.ITEM_PURCHASING,
    translatedTextKey: "features_itemSubNavs_itemPurchasing",
    show: itemTypes.BUY,
  },
  itemProduction: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION,
    translatedTextKey: "features_itemSubNavs_itemProduction",
    show: itemTypes.MAKE,
  },
  itemProductionBom: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_BOM,
    translatedTextKey: "features_itemSubNavs_itemProductionBom",
  },
  itemProductionRouting: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_ROUTING,
    translatedTextKey: "features_itemSubNavs_itemProductionRouting",
  },
  itemProductionHistory: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_HISTORY,
    translatedTextKey: "features_itemSubNavs_itemProductionHistory",
  },
  itemInventory: {
    icon: null,
    route: itemSubRoutes.ITEM_INVENTORY,
    translatedTextKey: "features_itemSubNavs_itemInventory",
  },
  itemSales: {
    icon: null,
    route: itemSubRoutes.ITEM_SALES,
    translatedTextKey: "features_itemSubNavs_itemSales",
    show: itemTypes.SELL,
  },
  itemShipments: {
    icon: null,
    route: itemSubRoutes.ITEM_SHIPMENTS,
    translatedTextKey: "features_itemSubNavs_itemShipments",
    show: itemTypes.SELL,
  },
  itemAutomations: {
    icon: null,
    route: itemSubRoutes.ITEM_AUTOMATIONS,
    translatedTextKey: "features_itemSubNavs_itemAutomations",
  },
}
export const jobSubNavs: FeatureNavs = {
  jobDetails: {
    icon: null,
    route: jobSubRoutes.JOB_DETAILS,
    translatedTextKey: "features_jobSubNavs_jobDetails",
  },
  jobProduction: {
    icon: null,
    route: jobSubRoutes.JOB_PRODUCTION,
    translatedTextKey: "features_jobSubNavs_jobProduction",
  },
  jobMaterials: {
    icon: null,
    route: jobSubRoutes.JOB_MATERIALS,
    translatedTextKey: "features_jobSubNavs_jobMaterials",
  },
  jobQuality: {
    icon: null,
    route: jobSubRoutes.JOB_QUALITY,
    translatedTextKey: "features_jobSubNavs_jobQuality",
  },
}
export const receivingSubNavs: FeatureNavs = {
  receivingGeneral: {
    icon: null,
    route: receivingSubRoutes.RCV_GENERAL,
    translatedTextKey: "features_receivingSubNavs_receivingGeneral",
  },
  receivingDetails: {
    icon: null,
    route: receivingSubRoutes.RCV_DETAILS,
    translatedTextKey: "features_receivingSubNavs_receivingDetails",
  },
}
export const inventorySubNavs: FeatureNavs = {
  inventoryDetails: {
    icon: null,
    route: inventorySubRoutes.INVENTORY_DETAILS,
    translatedTextKey: "features_inventorySubNavs_inventoryDetails",
  },
  inventoryActivity: {
    icon: null,
    route: inventorySubRoutes.INVENTORY_ACTIVITY,
    translatedTextKey: "features_inventorySubNavs_inventoryActivity",
  },
}
export const moveInventorySubNavs: FeatureNavs = {
  inventoryMove: {
    icon: null,
    route: moveInventorySubRoutes.MOVE_INVENTORY_MOVE,
    translatedTextKey: "features_moveInventorySubNavs_inventoryMove",
  },
  inventoryHistory: {
    icon: null,
    route: moveInventorySubRoutes.MOVE_INVENTORY_HISTORY,
    translatedTextKey: "features_moveInventorySubNavs_inventoryHistory",
  },
}
export const warehouseLocationSubNavs: FeatureNavs = {
  locationDetails: {
    icon: null,
    route: warehouseLocationSubRoutes.WAREHOUSE_LOCATIONS_DETAILS,
    translatedTextKey: "features_warehouseLocationSubNavs_locationDetails",
  },
  locationInventory: {
    icon: null,
    route: warehouseLocationSubRoutes.WAREHOUSE_LOCATIONS_INVENTORY,
    translatedTextKey: "features_warehouseLocationSubNavs_locationInventory",
  },
}
export const shippingSubNavs: FeatureNavs = {
  orders: {
    icon: null,
    route: shippingSubRoutes.SHIPPING_ORDERS,
    translatedTextKey: "features_shippingSubNavs_orders",
  },
  shipments: {
    icon: null,
    route: shippingSubRoutes.SHIPPING_SHIPMENTS,
    translatedTextKey: "features_shippingSubNavs_shipments",
  },
}
export const shippingModalSubNavs: FeatureNavs = {
  selectOrders: {
    icon: null,
    route: shippingModalSubRoutes.SELECT_ORDERS,
    translatedTextKey: "features_shippingModalSubNavs_selectOrders",
  },
  shippingPick: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_PICK,
    translatedTextKey: "features_shippingModalSubNavs_shippingPick",
  },
  shippingPack: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_PACK,
    translatedTextKey: "features_shippingModalSubNavs_shippingPack",
  },
  shippingShip: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_SHIP,
    translatedTextKey: "features_shippingModalSubNavs_shippingShip",
  },
}
export const manufacturingOrderSubNavs: FeatureNavs = {
  overview: {
    icon: null,
    route: manufacturingOrderSubRoutes.MANUFACTURING_ORDER_OVERVIEW,
    translatedTextKey: "mo:subNavs_overview",
  },
  quality: {
    icon: null,
    route: manufacturingOrderSubRoutes.MANUFACTURING_ORDER_QUALITY,
    translatedTextKey: "mo:subNavs_quality",
  },
  costing: {
    icon: null,
    route: manufacturingOrderSubRoutes.MANUFACTURING_ORDER_COSTING,
    translatedTextKey: "mo:subNavs_costing",
  },
}
export const workOrderSubNavs: FeatureNavs = {
  production: {
    icon: null,
    route: workOrderSubRoutes.WORK_ORDER_PRODUCTION,
    translatedTextKey: "wo:subNavs_production",
  },
  materials: {
    icon: null,
    route: workOrderSubRoutes.WORK_ORDER_MATERIALS,
    translatedTextKey: "wo:subNavs_materials",
  },
  quality: {
    icon: null,
    route: workOrderSubRoutes.WORK_ORDER_QUALITY,
    translatedTextKey: "wo:subNavs_quality",
  },
  costing: {
    icon: null,
    route: workOrderSubRoutes.WORK_ORDER_COSTING,
    translatedTextKey: "mo:subNavs_costing",
  },
}

export interface MenuItemProps {
  icon: React.ReactNode
  translatedTextKey: string
  route: string
  show?: string
}

// The MenuLists control that the tabs are showing up at the top of each page
const DashboardMenuList: MenuItemProps[] = [featureNavs.dashboard]

const FinanceMenuList: MenuItemProps[] = [featureNavs.purchase_orders]

const ReportingMenuList: MenuItemProps[] = [featureNavs.reports]

const OpsMenuList: MenuItemProps[] = [
  featureNavs.items,
  featureNavs.inventory,
  featureNavs.receiving,
  featureNavs.planning,
  featureNavs.production,
  featureNavs.manufacturing_orders,
  featureNavs.work_orders,
  featureNavs.shipping,
]

const SalesMenuList: MenuItemProps[] = [
  featureNavs.quotes,
  featureNavs.orders,
  featureNavs.order_items,
]

const PeopleMenuList: MenuItemProps[] = [
  featureNavs.companies,
  featureNavs.contacts,
  featureNavs.users,
]

const SettingsMenuList: MenuItemProps[] = [
  featureNavs.settings,
  featureNavs.permissionsBilling,
  featureNavs.customFields,
  featureNavs.integrations,
  featureNavs.operations,
  featureNavs.tools,
  featureNavs.warehouseLocations,
  featureNavs.customLabels,
  featureNavs.customDocs,
  featureNavs.customForms,
  featureNavs.equipment,
]

export const ItemSubMenuList: MenuItemProps[] = [
  itemSubNavs.itemDetails,
  itemSubNavs.itemVariants,
  itemSubNavs.itemVendors,
  itemSubNavs.itemPurchasing,
  itemSubNavs.itemProduction,
  itemSubNavs.itemInventory,
  itemSubNavs.itemSales,
  itemSubNavs.itemShipments,
  itemSubNavs.itemAutomations,
]
export const ItemProductionSubMenuList: MenuItemProps[] = [
  itemSubNavs.itemProductionBom,
  itemSubNavs.itemProductionRouting,
  itemSubNavs.itemProductionHistory,
]

export const ReceivingSubMenuList: MenuItemProps[] = [
  receivingSubNavs.receivingGeneral,
  receivingSubNavs.receivingDetails,
]

export const InventorySubMenuList: MenuItemProps[] = [
  inventorySubNavs.inventoryDetails,
  inventorySubNavs.inventoryActivity,
]

export const MoveInventorySubMenuList: MenuItemProps[] = [
  moveInventorySubNavs.inventoryMove,
  moveInventorySubNavs.inventoryHistory,
]

export const JobSubMenuList: MenuItemProps[] = [
  jobSubNavs.jobDetails,
  jobSubNavs.jobProduction,
  jobSubNavs.jobMaterials,
  jobSubNavs.jobQuality,
]
export const ManufacturingOrderSubMenuList: MenuItemProps[] = [
  manufacturingOrderSubNavs.overview,
  manufacturingOrderSubNavs.quality,
  ...(process.env.REACT_APP_SHOW_JOB_COSTING
    ? [manufacturingOrderSubNavs.costing]
    : []),
]
export const WorkOrderSubMenuList: MenuItemProps[] = [
  workOrderSubNavs.materials,
  workOrderSubNavs.production,
  workOrderSubNavs.quality,
  ...(process.env.REACT_APP_SHOW_JOB_COSTING ? [workOrderSubNavs.costing] : []),
]

export const WarehouseLocationSubMenuList: MenuItemProps[] = [
  warehouseLocationSubNavs.locationDetails,
  warehouseLocationSubNavs.locationInventory,
]

export const ShippingSubMenuList: MenuItemProps[] = [
  shippingSubNavs.orders,
  shippingSubNavs.shipments,
]
export const ShippingModalSubMenuList: MenuItemProps[] = [
  shippingModalSubNavs.selectOrders,
  shippingModalSubNavs.shippingPick,
  shippingModalSubNavs.shippingPack,
  shippingModalSubNavs.shippingShip,
]

/**
 * Remove this once we remove the concept of the "Hub"
 * @deprecated
 */
export const SidebarMenuListLegacy = {
  home: DashboardMenuList,
  finance: FinanceMenuList,
  operations: OpsMenuList,
  people: PeopleMenuList,
  reporting: ReportingMenuList,
  sales: SalesMenuList,
  settings: SettingsMenuList,
}

export const sidebarSubMenus: SubMenu[] = [
  {
    id: "sales",
    translatedTextKey: "sales",
    items: [featureNavs.quotes, featureNavs.orders, featureNavs.order_items],
  },
  {
    id: "finance",
    translatedTextKey: "finance",
    items: [featureNavs.purchase_orders],
  },
  {
    id: "operations",
    translatedTextKey: "operations",
    items: [
      featureNavs.items,
      featureNavs.inventory,
      featureNavs.receiving,
      featureNavs.planning,
      featureNavs.production,
      featureNavs.manufacturing_orders,
      featureNavs.work_orders,
      featureNavs.shipping,
    ],
  },

  // IMPORTANT: This id is used to check permission. DONT CHANGE unless you also update the filterUnauthorizedSubMenus function
  {
    id: "people",
    translatedTextKey: "people",
    items: [featureNavs.companies, featureNavs.contacts, featureNavs.users],
  },

  {
    // IMPORTANT: This id is used to check permission. DONT CHANGE unless you also update the filterUnauthorizedSubMenus function
    id: "reporting",
    translatedTextKey: "reporting",
    items: [featureNavs.reports],
  },
]

const settingsNavs: Record<
  SettingsPageName,
  Pick<FeatureNavItem, "icon" | "translatedTextKey">
> = {
  customFields: {
    icon: <PencilIcon />,
    translatedTextKey: "settingsModal:sidebar.customFields",
  },
  customLabels: {
    icon: <QrCodeIcon />,
    translatedTextKey: "settingsModal:sidebar.customLabels",
  },
  customDocs: {
    icon: <DocumentIcon />,
    translatedTextKey: "settingsModal:sidebar.customDocs",
  },
  customForms: {
    icon: <ClipboardDocumentIcon />,
    translatedTextKey: "settingsModal:sidebar.customForms",
  },
  equipment: {
    icon: <CogIcon />,
    translatedTextKey: "settingsModal:sidebar.equipment",
  },
  integrations: {
    icon: <Square3Stack3DIcon />,
    translatedTextKey: "settingsModal:sidebar.integrations",
  },
  operations: {
    icon: <ArrowPathRoundedSquareIcon />,
    translatedTextKey: "settingsModal:sidebar.operations",
  },
  permissionsBilling: {
    icon: <ShieldCheckIcon />,
    translatedTextKey: "settingsModal:sidebar.permissionsBilling",
  },
  general: {
    icon: <AdjustmentsHorizontalIcon />,
    translatedTextKey: "settingsModal:sidebar.general",
  },
  tools: {
    icon: <WrenchIcon />,
    translatedTextKey: "settingsModal:sidebar.tools",
  },
  warehouseLocations: {
    icon: <ArchiveBoxIcon />,
    translatedTextKey: "settingsModal:sidebar.warehouseLocations",
  },
}

export const getSettingsSidebarSubMenus = ({
  currentPageName,
  setPage,
}: {
  currentPageName: SettingsPageName
  setPage: (pageName: SettingsPageName) => void
}): SubMenu[] => {
  const getOnClickAndIsActiveProps = (pageName: SettingsPageName) => ({
    onClick: () => setPage(pageName),
    isActive: currentPageName === pageName,
  })

  return [
    {
      id: "account",
      translatedTextKey: "settingsModal:sidebar.account",
      items: [
        {
          ...settingsNavs.general,
          ...getOnClickAndIsActiveProps("general"),
        },
        {
          ...settingsNavs.permissionsBilling,
          ...getOnClickAndIsActiveProps("permissionsBilling"),
        },
        {
          ...settingsNavs.integrations,
          ...getOnClickAndIsActiveProps("integrations"),
        },
        {
          ...settingsNavs.customFields,
          ...getOnClickAndIsActiveProps("customFields"),
        },
      ],
    },
    {
      id: "production",
      translatedTextKey: "settingsModal:sidebar.production",
      items: [
        {
          ...settingsNavs.operations,
          ...getOnClickAndIsActiveProps("operations"),
        },
        {
          ...settingsNavs.equipment,
          ...getOnClickAndIsActiveProps("equipment"),
        },
        {
          ...settingsNavs.tools,
          ...getOnClickAndIsActiveProps("tools"),
        },
      ],
    },
    {
      id: "warehouse",
      translatedTextKey: "settingsModal:sidebar.warehouse",
      items: [
        {
          ...settingsNavs.warehouseLocations,
          ...getOnClickAndIsActiveProps("warehouseLocations"),
        },
      ],
    },
    {
      id: "documents",
      translatedTextKey: "settingsModal:sidebar.documents",
      items: [
        {
          ...settingsNavs.customDocs,
          ...getOnClickAndIsActiveProps("customDocs"),
        },
        {
          ...settingsNavs.customForms,
          ...getOnClickAndIsActiveProps("customForms"),
        },
        {
          ...settingsNavs.customLabels,
          ...getOnClickAndIsActiveProps("customLabels"),
        },
      ],
    },
  ]
}

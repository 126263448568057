import React, { useId } from "react"

import { ErrorMessage } from "@hookform/error-message"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import MuiSelect from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { Countries } from "country-and-province"
import { Controller } from "react-hook-form"

import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

type CountryType = {
  code: string
  label: string
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode
}

interface CountriesSelectProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: string
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  label?: string
  loading?: boolean
  name: string
  dataCy: string
}

const CountriesSelect: React.FC<CountriesSelectProps> = ({
  control,
  defaultValue = "US",
  errors,
  helperText,
  label = "Country",
  loading,
  name,
  dataCy,
}: CountriesSelectProps) => {
  const id = useId()
  const transformedCountries: readonly CountryType[] = Object.entries(
    Countries?.data,
  ).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([code, country]) => ({
      code: country.code,
      label: country.name,
    }),
  )

  const sortedCountries = [...transformedCountries].sort((a, b) =>
    a.label.localeCompare(b.label),
  )

  if (loading) {
    return (
      <InputContainerDiv>
        <Label>{label}</Label>
        <MuiSelect variant="outlined" fullWidth size="small" disabled />
      </InputContainerDiv>
    )
  }

  return (
    <InputContainerDiv>
      <Label htmlFor={id}>{label}</Label>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <Controller
        name={name}
        control={control}
        data-cy={dataCy}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            id={id}
            disablePortal
            options={sortedCountries || []}
            getOptionLabel={(option) =>
              option?.label ??
              sortedCountries.find(({ code }) => code === option)?.label ??
              ""
            }
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Box component="li" {...props}>
                {countryToFlag(option.code)}
                <span style={{ marginLeft: "12px" }}>
                  {option.label} ({option.code})
                </span>
              </Box>
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            renderInput={(params) => (
              <>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={Boolean(errors[name])}
                  helperText={helperText}
                  InputLabelProps={{ children: undefined }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <>{countryToFlag(field.value)}</>,
                  }}
                  size="small"
                />
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) => (
                    <FormHelperText error>{message}</FormHelperText>
                  )}
                />
              </>
            )}
            onChange={(_event, data) => field.onChange(data?.code ?? "")}
          />
        )}
      />
    </InputContainerDiv>
  )
}

export default CountriesSelect

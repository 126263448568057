import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react"

import CssBaseline from "@mui/material/CssBaseline"
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import useSession from "@hooks/useSession"

import ls, { localKeys } from "@services/localStorage"

import { colors } from "@constants/colors"
import { viewModes } from "@constants/serverEnums"

interface ThemeContextType {
  darkMode: boolean
  toggleDarkMode: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const themeOptions = (darkMode: boolean): ThemeOptions => ({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "FFFFFF",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(30, 30, 30, 0.55)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // needed so only one modal overlay (the top most) is shown when many modals are open
          '&[aria-hidden="true"] .MuiBackdrop-root': {
            opacity: "0 !important",
            transition: "opacity 225ms ease-in-out !important",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          fontSize: "14px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "14px",
        },
      },
    },
  },
  palette: {
    mode: darkMode ? "dark" : "light",
    primary: {
      main: darkMode ? colors.STANDARD_WHITE : colors.PRIMARY, // Primary color (for buttons, etc.)
      contrastText: darkMode ? colors.PRIMARY : colors.STANDARD_WHITE, // Text color on the primary background
    },
    secondary: {
      main: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Secondary color (for accents, etc.)
      contrastText: colors.STANDARD_WHITE, // Text color on the secondary background
    },
    error: {
      main: colors.BUTTON_RED, // Error color
    },
    // warning: {
    //   main: "#ff9800", // Warning color
    // },
    // info: {
    //   main: "#2196f3", // Info color
    // },
    success: {
      main: colors.STATUS_GREEN_TXT, // Success color
    },
    text: {
      primary: darkMode ? colors.STANDARD_WHITE : colors.TEXT_DARK, // Primary text color
      secondary: darkMode ? colors.STANDARD_WHITE : colors.TEXT_DARK_GRAY, // Secondary text color
    },
    // background: {
    //   default: "#f0f0f0", // Default background color
    //   paper: "#fff", // Paper (card) background color
    // },
    divider: darkMode ? colors.STANDARD_WHITE : colors.BORDER_GRAY, // Divider color
    // action: {
    //   active: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Active state color (for buttons, etc.)
    //   hover: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Hover state color (for buttons, etc.)
    // },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: "Inter, Roboto, Arial, sans-serif",
    button: {
      fontSize: "13px",
      fontWeight: 500,
      textTransform: "none", // This sets the button text to regular capitalization
    },
    h1: {
      fontSize: "96px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "60px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 700,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 700,
    },
    h6: {
      fontSize: "13px",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "125%",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "150%",
    },
    body2: {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "125%",
    },
    caption: {
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "100%",
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThemeContext: any = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider")
  }
  return context
}

interface AppThemeProviderProps {
  children: React.ReactNode
}

const AppThemeProvider: React.FC<AppThemeProviderProps> = ({
  children,
}: AppThemeProviderProps) => {
  const { orgMember } = useSession()
  const serverSavedDark = orgMember?.settings?.viewMode === viewModes.DARK
  const lsSavedDark = ls.getItem(localKeys.VIEW_MODE) === viewModes.DARK
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  // use user's browser preference first
  // fall back to local storage value if it exists
  // use the value from the server last (as it is slowest)
  const [darkMode, setDarkMode] = useState(
    serverSavedDark || lsSavedDark || prefersDarkMode,
  )

  useEffect(() => {
    setDarkMode(serverSavedDark)
  }, [setDarkMode, serverSavedDark])

  const memoizedValue = useMemo(() => {
    const themeValue: ThemeContextType = {
      darkMode,
      toggleDarkMode: () => {
        setDarkMode((prevMode) => !prevMode)
      },
    }
    return themeValue
  }, [darkMode, setDarkMode])

  const theme = React.useMemo(
    () => createTheme(themeOptions(darkMode)),
    [darkMode],
  )

  return (
    <ThemeContext.Provider value={memoizedValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default AppThemeProvider

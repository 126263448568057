import * as React from "react"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { Link, matchPath, useLocation, useParams } from "react-router-dom"

import FavoriteFeature from "@components/FavoriteFeature"

import { SidebarMenuListLegacy, MenuItemProps } from "@constants/features"
import routes from "@constants/routes"

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

function getMainRouteFromChildRoute(childRoute: string, id: string) {
  if (!id) return childRoute

  const childRouteSplit = childRoute.split(`/${id}`)

  return childRouteSplit[0]
}

interface TabContentProps {
  active?: boolean
  label: string
  favoriteFeature?: string
}

const TabContent = ({ active, label, favoriteFeature }: TabContentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault()
    setAnchorEl(null)
  }

  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleClick(e)
  }

  return (
    <Typography
      variant="body1"
      // makes navigation less jumpy
      sx={{
        borderBottom:
          !active || !favoriteFeature ? "3px solid transparent" : "",
        pb: !favoriteFeature ? "2px" : "",
      }}
    >
      {label}{" "}
      {active && favoriteFeature && (
        <IconButton
          aria-controls={open ? "tab-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          id="tab-button"
          onClick={showMenu}
          sx={{ pt: 0, pb: 0 }}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <Menu
        id="tab-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "tab-button",
        }}
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiButtonBase-root": { p: 0.5 },
        }}
      >
        <MenuItem onClick={handleClose}>
          {favoriteFeature && <FavoriteFeature feature={favoriteFeature} />}
        </MenuItem>
      </Menu>
    </Typography>
  )
}

interface TabsRouterProps {
  favoriteFeature?: string
  hub?: string
}

const TabsRouter: React.FC<TabsRouterProps> = ({
  favoriteFeature,
  hub,
}: TabsRouterProps) => {
  const { t } = useTranslation()
  const { tab, orderId, itemId } = useParams()

  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/add, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch([
    routes.COMPANIES,
    routes.CONTACTS,
    routes.DASHBOARD,
    routes.INVENTORY,
    `${routes.ITEMS}/${itemId}/${tab}`,
    routes.ITEMS,
    routes.MANUFACTURING_ORDERS,
    routes.ORDERS,
    routes.ORDER_ITEMS,
    routes.QUOTES,
    routes.PLANNING,
    routes.PRODUCTION,
    routes.PURCHASE_ORDERS,
    routes.RECEIVING,
    routes.REPORTING,
    routes.REPORTS,
    `${routes.SHIPPING}/${orderId}/${tab}`,
    routes.SHIPPING,
    routes.USERS,
    routes.WORK_ORDERS,
  ])
  const currentTab = routeMatch?.pattern?.path
  const hubModules = (SidebarMenuListLegacy as Record<string, MenuItemProps[]>)[
    hub?.toLowerCase() || ""
  ]

  if (currentTab === undefined) {
    return null
  }

  const isChildRoute =
    currentTab?.includes(itemId || "") || currentTab?.includes(orderId || "")
  const getMainRoute = isChildRoute
    ? getMainRouteFromChildRoute(currentTab, itemId || orderId || "")
    : currentTab

  return (
    <Tabs
      allowScrollButtonsMobile
      scrollButtons="auto"
      sx={{ minHeight: "30px", width: "60vw" }}
      value={getMainRoute}
      variant="scrollable"
    >
      {hubModules?.map(({ route, translatedTextKey }: MenuItemProps) => {
        return (
          <Tab
            component={Link}
            key={translatedTextKey}
            label={
              <TabContent
                active={currentTab === route}
                label={t(translatedTextKey)}
                favoriteFeature={favoriteFeature}
              />
            }
            sx={{
              alignItems: !favoriteFeature ? "flex-start" : "center",
              minHeight: "30px",
              minWidth: "inherit",
              mr: currentTab !== route || !favoriteFeature ? "44px" : "",
              p: 0,
            }}
            to={route}
            value={route}
          />
        )
      })}
    </Tabs>
  )
}

export default TabsRouter
